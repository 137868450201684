<!--Vista de actualización de contraseña
  En esta sección se ingresa el email del usuario del que desean recuperar, la contraseña y la nueva contraseña
-->
<template>
  <v-container
    fluid
    class="pb-0 px-0 d-flex align-center"
  >
    <v-row
      justify="center"
      class="d-flex align-center background-image"
    >
      <v-col cols="11" sm="7" md="5" lg="4" xl="3">
        <v-card class="px-8 pt-10 mt-16" rounded="xl">
          <v-form ref="passwordRecoverForm" v-model="validPasswordRecoverForm">
            <v-row>
              <v-col cols="12" class="d-flex justify-center mb-8">
                <v-img
                  src="@/assets/images/branding/nova-pocket.png"
                  max-width="230"
                  max-height="230"
                ></v-img>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-text-field
                  v-model.trim="dataUser.email"
                  elevation="0"
                  label="Correo electrónico"
                  type="email"
                  outlined
                  rounded
                  :rules="emailRules"
                  @keyup="validateEmail()"
                  @blur="validateEmail()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-text-field
                  v-model="dataUser.password"
                  :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                  label="Contraseña nueva"
                  :type="showPass ? 'text' : 'password'"
                  outlined
                  rounded
                  autocomplete="new-password"
                  :rules="passwordRules"
                  @click:append="showPass = !showPass"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-text-field
                  v-model="dataUser.confirmPassword"
                  :append-icon="showConfirmPass ? 'mdi-eye' : 'mdi-eye-off'"
                  label="Confirmar contraseña"
                  :type="showConfirmPass ? 'text' : 'password'"
                  outlined
                  rounded
                  aria-autocomplete="false"
                  :rules=" [(dataUser.password==dataUser.confirmPassword) || 'La confirmación de contraseña no es correcta']"
                  @click:append="showConfirmPass = !showConfirmPass"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="d-flex justify-center mb-7">
                <v-btn
                  color="accent"
                  rounded
                  large
                  @click="_passwordReset()"
                  :loading="loadingButton"
                  :disabled="
                    dataUser.email == '' ||
                    dataUser.password == '' ||
                    dataUser.confirmPassword == '' ||
                    !validPasswordRecoverForm
                  "
                  >
                    Actualizar
                  </v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapMutations, mapActions } from "vuex";
import { validations } from "@/share/Validations";
export default {
  data() {
    return {
      dataUser: {
        token: "",
        email: "",
        password: "",
        confirmPassword: "",
      },
      loadingButton: false,
      showPass: false,
      showConfirmPass: false,
      validEmail: true,
      validPassword: true,
      validConfirmPassword: true,
      validPasswordRecoverForm: false,
      // Validaciones
      emailRules: validations.emailValidation({
        name: "email",
        required: true,
      }),
      passwordRules: validations.generalMValidation({
        required: true,
        name: "contraseña",
        minLength: 8,
      }),
    };
  },
  methods: {
    ...mapActions("auth", ["passwordReset"]),
    ...mapMutations("loader", ["loading", "loaded"]),
    ...mapMutations("notification", ["show"]),
    /**
     * Función para validar el email
     */
    validateEmail() {
      this.validEmail = /.+@.+\..+/.test(this.dataUser.email);
    },
    /**
     * Función para validar el campo de email.
     */
    validatePassword() {
      this.validPassword = this.dataUser.password.length > 7;
      if (!this.validConfirmPassword || this.dataUser.confirmPassword != "") {
        this.validConfirmPassword =
          this.dataUser.confirmPassword == this.dataUser.password;
      }
    },
    /**
     * Función para validar el campo de confirmación de contraseña
     */
    validateConfirmPassword() {
      this.validConfirmPassword =
        this.dataUser.confirmPassword == this.dataUser.password;
    },
    /**
     * Función para cambiar la contraseña
     */
    async _passwordReset() {
      this.loadingButton = true;
      let response = await this.passwordReset(this.dataUser);
      if (response.status && response.status > 199 && response.status < 300) {
        this.show({
          text: "Actualización de contraseña exitoso",
          color: "accent",
        });
        this.$router.push("/");
      } else {
        if (response.data.error.email) {
          this.show({ text: response.data.error.email, color: "red accent-3" });
        } else {
          let error = new ErrorHandler();
          this.show({
            text: error.formatError(response.status),
            color: "red accent-3",
          });
        }
      }
      this.loadingButton = false;
    },
  },
  mounted() {
    this.dataUser.token = this.$route.params.token;
  },
};
</script>
<style scoped>
/* Estilos de boton deshabilitado */
/*#custom-disabled.v-btn--disabled {
  background-color: #e0e0e0 !important;
}*/

.password-reset-container {
  min-height: 100vh;
  background: rgb(0, 99, 175);
  background: linear-gradient(
    121deg,
    rgba(0, 99, 175, 1) 38%,
    rgba(24, 157, 217, 1) 88%
  );
}
/*.background-image {
  min-height: 98vh;
  background-image: url("../assets/images/branding/nova-white-trans.png");
  background-size: 70%;
  background-position: center center;
}*/

.background-image {
  min-height: 103vh;
  width: 100vw;
  background-image: url("../assets/images/login-background.png");
  background-repeat: repeat-x;
  /*background-position: 0 0;*/
  background-position: center;
  background-size: cover;
}

.v-text-field--outlined >>> fieldset {
  border-color: #006cb7;
  border-width: 2px;
  color: #006cb7;
}
</style>